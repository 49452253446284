import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userPreferences from '@/store/userPreferences'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useList(abilityRequired, appStoreModuleName) {
  // Use toast
  const toast = useToast()

  const userPrefs = userPreferences()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    { key: 'email', sortable: true },
    { key: 'phone', sortable: false },
    { key: 'role', sortable: true },
  ]
  const perPage = ref(10)
  if (userPrefs.getUserPref('onboardingPerPage') !== null) {
    // get the current value for per page
    perPage.value = userPrefs.getUserPref('onboardingPerPage')
  }
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('last_name')
  const isBusy = ref(false)
  const isSortDirDesc = ref(false)
  const theList = ref(null)
  const theListComplete = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    console.log('refetchData')
    console.log(refListTable.value.$attrs['force-refresh'])
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    const tmp = { onboardingPerPage: perPage.value }
    userPrefs.saveUserPrefs(tmp)
    refetchData()
  })

  const fetchListItems = (ctx, callback) => {
    console.log('fetchListItems')
    console.log(ctx)
    if (ability.can('read', abilityRequired)) {
      console.log('PASSED')
      const qvars = {
        forceRefresh: true,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      }
      console.log(qvars)
      store
        .dispatch(`${appStoreModuleName}/fetchItems`, qvars)
        .then(response => {
          const theItems = response.data
          console.log(callback)
          callback(theItems)
          theListComplete.value = theItems
          totalItems.value = response.data.totalItems
        })
        .catch(error => {
          console.log(error) // No current user
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  // Get the list of clients
  const qvars = {}
  qvars.status = 'active'
  qvars.q = null
  qvars.service = null

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const formatPhoneNumber = phone => {
    try {
      if (typeof phone !== 'undefined' && phone.length === 10) return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
      if (typeof phone !== 'undefined' && phone.length === 11) return `${phone.substring(0, 1)} (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7, 11)}`
    } catch (e) { console.log(e) }
    return phone
  }

  const resolveUserRoleVariant = role => {
    // if (role === 'subscriber') return 'primary'
    // if (role === 'author') return 'warning'
    // if (role === 'maintainer') return 'success'
    // if (role === 'editor') return 'info'
    if (role === 'Management') return 'danger'
    return 'info'
  }

  const resolveUserRoleIcon = role => {
    // if (role === 'subscriber') return 'UserIcon'
    // if (role === 'author') return 'SettingsIcon'
    // if (role === 'maintainer') return 'DatabaseIcon'
    // if (role === 'editor') return 'Edit2Icon'
    if (role === 'Management') return 'UserIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'disabled') return 'secondary'
    return 'success'
  }

  return {
    fetchListItems,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    theList,
    theListComplete,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refListTable,
    refetchData,

    formatPhoneNumber,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
  }
}
